import axiosInstance from '../../axios';

    const getProfile = () => {
        return axiosInstance.get(`user/profile/`);
    };

    const updateProfile = (data) => {
        return axiosInstance.put(`user/profile/`, data);
    };

    const updatePassword = (data) => {
        return axiosInstance.put(`user/profile/password/`, data);
    };

    const updateAvatar = (data) => {
        return axiosInstance.put(`user/profile/avatar/`, data);
    };

    const deleteAvatar = () => {
        return axiosInstance.delete(`user/profile/avatar/`);
    };

    const createUser = (data) => {
        return axiosInstance.post(`user/`, data);
    };

    const listUser = () => {
        return axiosInstance.get(`user/`);
    };

    const updateUser = (userId, data) => {
        return axiosInstance.put(`user/${userId}/`, data);
    };

    const deleteUser = (userId) => {
        return axiosInstance.delete(`user/${userId}/`);
    };

    const checksuperUser = () => {
        return axiosInstance.get(`user/register/`);
    };

    const createsuperUser = (data) => {
        return axiosInstance.post(`user/register/`, data);
    };

    const checkUser = (data) => {
        return axiosInstance.post(`user/checkuser/`, data);
    };

    const listActivities = (userId) => {
        return axiosInstance.get(`user/activities/${userId}/`);
    };




    const userServices = {
        getProfile,
        updateProfile,
        updatePassword,
        createUser,
        listUser,
        updateUser,
        deleteUser,
        updateAvatar,
        deleteAvatar,
        checksuperUser,
        checkUser,
        createsuperUser,
        listActivities,
    };

    export default userServices;