import axios from 'axios';

//const baseURL = 'http://127.0.0.1:8000/api/';
const baseURL = window._env_.API_URL;

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('accessToken')
			? 'JWT ' + localStorage.getItem('accessToken')
			: null,
		'Content-Type': 'multipart/form-data',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'token/refresh/'
		) {
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
			window.location.href = '/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401
		) {
			const refreshToken = localStorage.getItem('refreshToken');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				//exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				//console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('accessToken', response.data.access);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							//console.log(err);
						});
				} else {
					//console.log('Refresh token is expired', tokenParts.exp, now);
					localStorage.removeItem('accessToken')
					localStorage.removeItem('refreshToken')
					window.location.href = '/';
				}
			} else {
				//console.log('Refresh token not available.');
				localStorage.removeItem('accessToken')
				localStorage.removeItem('refreshToken')
				window.location.href = '/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;