import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const portfolioidSlice = createSlice({
    name: "portfolioid",
    initialState,
    reducers: {
        setPortfolioId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setPortfolioId } = portfolioidSlice.actions
export default portfolioidSlice.reducer