import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const portfolionameSlice = createSlice({
    name: "portfolioname",
    initialState,
    reducers: {
        setPortfolioName: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setPortfolioName } = portfolionameSlice.actions
export default portfolionameSlice.reducer