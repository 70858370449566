import { lazy } from "react";
import { Loadable } from "app/components";

const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));

const sessionRoutes = [
  { path: "/signin", element: <JwtLogin /> },
];

export default sessionRoutes;
