import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const portfoliocategoryidSlice = createSlice({
    name: "portfoliocategoryid",
    initialState,
    reducers: {
        setPortfolioCategoryId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setPortfolioCategoryId } = portfoliocategoryidSlice.actions
export default portfoliocategoryidSlice.reducer