//import useSettings from "app/hooks/useSettings";

export default function MatxLogo({ className }) {
  //const { settings } = useSettings();
  //const theme = settings.themes[settings.activeTheme];

  return (
<svg id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" width="22.47" height="22.47" viewBox="0 0 22.47 22.47">
  <title>dportfolio logo menu</title>
  <path d="M11.84,12.24,11.63.78A12.33,12.33,0,0,0,6,2.49,12.09,12.09,0,0,0,1.5,7.9a11.47,11.47,0,0,0,0,8,12,12,0,0,0,4.09,5.34A13.7,13.7,0,0,0,9.8,23.07a12.94,12.94,0,0,0,4.12,0,6.69,6.69,0,0,0,2.63-.75Z" transform="translate(-0.76 -0.76)" fill="#ed2024"/>
  <path d="M12.29,11.45,12.16.78a9.62,9.62,0,0,1,4.56,1,9.92,9.92,0,0,1,3.75,2.81Z" transform="translate(-0.76 -0.76)" fill="#fec20f"/>
  <path d="M12.41,12l8.38-7a10.69,10.69,0,0,1,2.31,5.22,11,11,0,0,1-.63,5.81A11.1,11.1,0,0,1,20,20a12.91,12.91,0,0,1-3,2.13Z" transform="translate(-0.76 -0.76)" fill="#31b44a"/>
</svg>
);
}
