import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import { routes } from 'app/routes/routes'

const getUserRoleAuthStatus = (pathname, user, routes) => {
    const matched = routes.find((r) => r.path === pathname);
    try{
        const auth = matched.auth
        const authenticated =
            matched && auth && matched.auth.length
            ? user.permission.some(item => matched.auth.includes(item))
            : true;
            return authenticated;
        }
    catch { return true }
};

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth()
    const { pathname } = useLocation()
    const isUserRoleAuthenticated = getUserRoleAuthStatus(pathname, user, routes);

    let authenticated = isAuthenticated && isUserRoleAuthenticated;
    
    if (authenticated) return <>{children}</>
    
    else {
        if (!isAuthenticated)
            return (
                <Navigate
                    to={{
                        pathname: '/signin',
                    }}
                />
            )
        if (!isUserRoleAuthenticated)
            return (
                <Navigate
                    to={{
                        pathname: '/dashboard',
                    }}
                />
            )
    }
}

export default AuthGuard
