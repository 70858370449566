import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const portfoliocategorynameSlice = createSlice({
    name: "portfoliocategoryname",
    initialState,
    reducers: {
        setPortfolioCategoryName: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setPortfolioCategoryName } = portfoliocategorynameSlice.actions
export default portfoliocategorynameSlice.reducer