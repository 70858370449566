import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const investmentidSlice = createSlice({
    name: "investmentid",
    initialState,
    reducers: {
        setInvestmentId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setInvestmentId } = investmentidSlice.actions
export default investmentidSlice.reducer