import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const clientidSlice = createSlice({
    name: "clientid",
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setClientId } = clientidSlice.actions
export default clientidSlice.reducer