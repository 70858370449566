import AuthGuard from "app/auth/AuthGuard";
import sessionRoutes from "app/views/sessions/session-routes"
import administrationRoutes from "app/views/administration/AdministrationRoutes"
import userRoutes from "app/views/user/UserRoutes"
import dashboardRoutes from "app/views/dashboard/DashboardRoutes"
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'

export const AllPages = () => {
  const all_routes = [
      {
          element: (
              <AuthGuard>
                  <MatxLayout />
              </AuthGuard>
          ),
          children: [...dashboardRoutes, ...administrationRoutes, ...userRoutes],
      },
      ...sessionRoutes,
      {
          path: '/',
          element: <Navigate to="/dashboard" />,
      },
      {
          path: '*',
          element: <Navigate to="/dashboard" />,
      },
  ]

  return all_routes
}

export const routes = [
    ...dashboardRoutes,
    ...administrationRoutes,
    ...userRoutes,
]
