import { configureStore } from '@reduxjs/toolkit'
import navigationReducer from 'app/redux/slices/navigationSlice.js'
import fundnameReducer from 'app/redux/slices/fundnameSlice.js'
import fundidReducer from 'app/redux/slices/fundidSlice.js'
import clientnameReducer from 'app/redux/slices/clientnameSlice.js'
import clientidReducer from 'app/redux/slices/clientidSlice.js'
import typeidReducer from 'app/redux/slices/typeidSlice.js'
import typenameReducer from 'app/redux/slices/typenameSlice.js'
import portfoliocategorynameReducer from 'app/redux/slices/portfoliocategorynameSlice.js'
import portfoliocategoryidReducer from 'app/redux/slices/portfoliocategoryidSlice.js'
import portfolioidReducer from 'app/redux/slices/portfolioidSlice.js'
import portfolionameReducer from 'app/redux/slices/portfolionameSlice.js'
import investmentidReducer from 'app/redux/slices/investmentidSlice.js'
import policyidReducer from 'app/redux/slices/policyidSlice.js'


const store = configureStore({
    reducer: {
      navigation: navigationReducer,
      fundName: fundnameReducer,
      fundId: fundidReducer,
      clientName: clientnameReducer,
      clientId: clientidReducer,
      typeId: typeidReducer,
      typeName: typenameReducer,
      portfolioCategoryName: portfoliocategorynameReducer,
      portfolioCategoryId: portfoliocategoryidReducer,
      portfolioId: portfolioidReducer,
      portfolioName: portfolionameReducer,
      investmentId: investmentidReducer,
      policyId: policyidReducer,
    }
  })

  export default store