import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import store from './redux/Store'
import { Provider } from 'react-redux'

import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
//import routes from "./routes";
import { AllPages } from './routes/routes'

export default function App() {
  const content = useRoutes(AllPages());

  return (
    <Provider store={store}>
      <SettingsProvider>
        <AuthProvider>
          <MatxTheme>
            <CssBaseline />
            {content}
          </MatxTheme>
        </AuthProvider>
      </SettingsProvider>
    </Provider>
  );
}
