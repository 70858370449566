import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const fundidSlice = createSlice({
    name: "fundid",
    initialState,
    reducers: {
        setFundId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setFundId } = fundidSlice.actions
export default fundidSlice.reducer