import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import userServices from "app/services/userServices";
import { setUserNavigation } from 'app/redux/slices/navigationSlice.js'
import { useDispatch } from 'react-redux'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const setSession = (accessToken, refreshToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        axios.defaults.headers['Authorization'] = 'JWT ' + localStorage.getItem('accessToken');
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        delete axios.defaults.headers['Authorization']
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }

        case 'RELOAD': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }

        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
})

export const AuthProvider = ({ children }) => {
    const reduxDispatch = useDispatch()
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (user_name, password) => {
        const response = await axios.post('token/', {
            user_name,
            password,
        })

        const { accessToken, refreshToken, user } = response.data;
        setSession(accessToken, refreshToken);
        reduxDispatch(setUserNavigation(user.permission))

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const reload = async () => {
        const response = await userServices.getProfile()
        const user = response.data

        dispatch({
            type: 'RELOAD',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const refreshToken = window.localStorage.getItem('refreshToken')

                //if (accessToken && isValidToken(accessToken)) {
                if (accessToken) {
                    setSession(accessToken,refreshToken)
                    //const response = await axios.get('user/profile/')
                    const response = await userServices.getProfile()
                    const user = response.data
                    reduxDispatch(setUserNavigation(user.permission))
                    
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
        // eslint-disable-next-line
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                reload
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
