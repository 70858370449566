import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const typenameSlice = createSlice({
    name: "typename",
    initialState,
    reducers: {
        setTypeName: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setTypeName } = typenameSlice.actions
export default typenameSlice.reducer