import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const clientnameSlice = createSlice({
    name: "clientname",
    initialState,
    reducers: {
        setClientName: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setClientName } = clientnameSlice.actions
export default clientnameSlice.reducer