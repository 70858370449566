import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const fundnameSlice = createSlice({
    name: "fundname",
    initialState,
    reducers: {
        setFundName: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setFundName } = fundnameSlice.actions
export default fundnameSlice.reducer