import { lazy } from 'react'
import { Loadable } from 'app/components';


const AppUser = Loadable(lazy(() => import("./users/Users")))
const AppCompany = Loadable(lazy(() => import("./company/Company")))

const AppTax = Loadable(lazy(() => import("./fund/tax/Tax")))
const AppProvider = Loadable(lazy(() => import("./fund/provider/Provider")))
const AppFundCategory = Loadable(lazy(() => import("./fund/category/Category")))
const AppAssetClass = Loadable(lazy(() => import("./fund/assetclass/AssetClass")))
const AppFund = Loadable(lazy(() => import("./fund/fund/Fund")))
const AppFundDetail = Loadable(lazy(() => import("./fund/fund/FundDetail")))
const AppFundPerformance = Loadable(lazy(() => import("./fund/fundperformance/FundPerformance")))
const AppStock = Loadable(lazy(() => import("./fund/stock/Stock")))
const AppSector = Loadable(lazy(() => import("./fund/sector/Sector")))
const AppRegion = Loadable(lazy(() => import("./fund/region/Region")))

const AppDateFormat = Loadable(lazy(() => import("./fundprice/dateformat/DateFormat")))
const AppImportPrice = Loadable(lazy(() => import("./fundprice/importprice/ImportPrice")))
const AppScraper = Loadable(lazy(() => import("./fundprice/scraper/Scraper")))
const AppUpdatePrice = Loadable(lazy(() => import("./fundprice/updateprice/UpdatePrice")))
const AppScraperLogs = Loadable(lazy(() => import("./fundprice/scraperlogs/ScraperLogs")))
const AppTaskLogs = Loadable(lazy(() => import("./fundprice/tasklogs/TaskLogs")))
const AppFundPrice = Loadable(lazy(() => import("./fundprice/fundprice/FundPrice")))
const AppFundPriceDetail = Loadable(lazy(() => import("./fundprice/fundprice/FundPriceDetail")))

const AppType = Loadable(lazy(() => import("./portfolio/type/Type")))
const AppPortfolioCategory = Loadable(lazy(() => import("./portfolio/category/Category")))
const AppRiskProfile = Loadable(lazy(() => import("./portfolio/riskprofile/RiskProfile")))
const AppRiskProfileDetail = Loadable(lazy(() => import("./portfolio/riskprofile/RiskProfileDetail")))

const AppImportInvestment = Loadable(lazy(() => import("./client/ImportInvestment")))

const AppTakafulProvider = Loadable(lazy(() => import("./takaful/provider/Provider")))
const AppPaymentMethod = Loadable(lazy(() => import("./takaful/payment-method/PaymentMethod")))
const AppPaymentMode = Loadable(lazy(() => import("./takaful/payment-mode/PaymentMode")))
const AppStatus = Loadable(lazy(() => import("./takaful/status/Status")))
const AppPlan = Loadable(lazy(() => import("./takaful/plan/Plan")))

const administrationRoutes = [
//  Admin
    {
        path: '/admin/user',
        exact: true,
        element: <AppUser />,
        auth: ['admin']
    },
    {
        path: '/admin/company',
        exact: true,
        element: <AppCompany />,
        auth: ['admin']
        
    },


//  Funds
    {
        path: '/fund/tax',
        exact: true,
        element: <AppTax />,
        auth: ['admin']
    },
    {
        path: '/fund/provider',
        exact: true,
        element: <AppProvider />,
        auth: ['admin']
    },
    {
        path: '/fund/category',
        exact: true,
        element: <AppFundCategory />,
        auth: ['admin']
    },
    {
        path: '/fund/assetclass',
        exact: true,
        element: <AppAssetClass />,
        auth: ['admin']
    },
    {
        path: '/fund/stock',
        exact: true,
        element: <AppStock />,
        auth: ['admin']
    },
    {
        path: '/fund/sector',
        exact: true,
        element: <AppSector />,
        auth: ['admin']
    },
    {
        path: '/fund/region',
        exact: true,
        element: <AppRegion />,
        auth: ['admin']
    },
    {
        path: '/fund/fund',
        exact: true,
        element: <AppFund />,
        auth: ['admin']
    },
    {
        path: '/fund/fund/:fundId',
        exact: true,
        element: <AppFundDetail />,
        auth: ['admin']
    },

    {
        path: '/fund/fundperformance',
        exact: true,
        element: <AppFundPerformance />,
        auth: ['admin']
    },


//  Fundprice
    {
        path: '/fundprice/fundprice',
        exact: true,
        element: <AppFundPrice />,
        auth: ['admin']
    },
    {
        path: '/fundprice/fundprice/detail',
        exact: true,
        element: <AppFundPriceDetail />,
        auth: ['admin']
    },
    {
        path: '/fundprice/dateformat',
        exact: true,
        element: <AppDateFormat />,
        auth: ['admin']
    },
    {
        path: '/fundprice/importprice',
        exact: true,
        element: <AppImportPrice />,
        auth: ['admin']
    },
    {
        path: '/fundprice/scraper',
        exact: true,
        element: <AppScraper />,
        auth: ['admin']
    },
    {
        path: '/fundprice/updateprice',
        exact: true,
        element: <AppUpdatePrice />,
        auth: ['admin']
    },
    {
        path: '/fundprice/scraperlogs',
        exact: true,
        element: <AppScraperLogs />,
        auth: ['admin']
    },
    {
        path: '/fundprice/tasklogs',
        exact: true,
        element: <AppTaskLogs />,
        auth: ['admin']
    },

//  Portfolio
    {
        path: '/portfolio/type',
        exact: true,
        element: <AppType />,
        auth: ['admin']
    },
    {
        path: '/portfolio/category',
        exact: true,
        element: <AppPortfolioCategory />,
        auth: ['admin']
    },
    {
        path: '/portfolio/riskprofile',
        exact: true,
        element: <AppRiskProfile />,
        auth: ['admin']
    },
    {
        path: '/portfolio/riskprofile/:riskProfileId',
        exact: true,
        element: <AppRiskProfileDetail />,
        auth: ['admin']
    },
    {
        path: '/client/import-investment',
        exact: true,
        element: <AppImportInvestment />,
        auth: ['admin']
    },

    //  Takaful
    {
        path: '/takaful/provider',
        exact: true,
        element: <AppTakafulProvider />,
        auth: ['admin']
    },
    {
        path: '/takaful/payment-method',
        exact: true,
        element: <AppPaymentMethod />,
        auth: ['admin']
    },
    {
        path: '/takaful/payment-mode',
        exact: true,
        element: <AppPaymentMode />,
        auth: ['admin']
    },
    {
        path: '/takaful/status',
        exact: true,
        element: <AppStatus />,
        auth: ['admin']
    },
    {
        path: '/takaful/plan',
        exact: true,
        element: <AppPlan />,
        auth: ['admin']
    },
]

export default administrationRoutes