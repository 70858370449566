import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value : ''
}

const typeidSlice = createSlice({
    name: "typeid",
    initialState,
    reducers: {
        setTypeId: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setTypeId } = typeidSlice.actions
export default typeidSlice.reducer